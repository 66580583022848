"use strict"
showBurger();

function showBurger() {
  const burger = document.querySelector(".header-top__burger");
  const menu = document.querySelector(".nav");
  const body = document.body;

  burger.addEventListener("click", () => {
    menu.classList.toggle("nav--active");
    burger.classList.toggle("header-top__burger--active");
    body.classList.toggle("lock");
  });
  document.querySelectorAll(".nav__link").forEach((item) => {
    item.addEventListener("click", () => {
      menu.classList.remove("nav--active");
      burger.classList.remove("header-top__burger--active");
      body.classList.remove("lock");
    });
  });
}

AOS.init({
  // Global settings:
  disable: 'phone',  
  startEvent: 'DOMContentLoaded', 
  once: true,
})


const swiper = new Swiper('.swiper-container', {
  spaceBetween: 20,
  speed: 600,
  centeredSlides: true,
  grabCursor: true,
  pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',        
  }, 
  autoHeight: true,
  effect: 'coverflow',
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  },
		lazy: true,
		lazy: {			
			loadPrevNext: true,
			checkInView: true,
	},
  breakpoints: {		
    1025: {
      slidesPerView: 3,
    },
    480: {
      slidesPerView: 2,
    },
    300: {
      slidesPerView: 1,
      effect: 'slide',
      centeredSlides: false,
    }
}
});

window.addEventListener('load', () => {
  
  document.querySelectorAll('.--link').forEach(link => {
    link.addEventListener('click', scrollBehav)
  });  
  
  
  function scrollBehav(e) {
    e.preventDefault();
    scrollToId(this.hash);      
  }

  function scrollToId(id) {
    let section = document.querySelector(id);
    let pos = section.offsetTop - 60

    if("scrollBehavior" in document.body.style){
      window.scrollTo( {
        top: section.offsetTop - 60,
        behavior: "smooth"
      })
    } else {
      window.scrollTo(0, pos);
    }
  }
})

if(window.innerWidth >767){ showActiveLink() }
function showActiveLink() {
  const sections = document.querySelectorAll('.section');
  const config = {
    rootMargin: '-45% 0px -55%'
  };

  let observer = new IntersectionObserver(function (entries, self) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        intersectionHandler(entry); 
      }
    });
  }, config);

  sections.forEach(section => {
    observer.observe(section);
  });

  function intersectionHandler(entry) {
    const id = entry.target.id;
    const linkActive = document.querySelector('.nav__link.--active');
    const shouldBeActive = document.querySelector('.nav__link[href="' + "#" + id + '"]');
    if (linkActive) {
      linkActive.classList.remove('--active');
    }
    if (shouldBeActive) {
      shouldBeActive.classList.add('--active');
    }
  }
}

let selector = document.querySelectorAll('input[type="tel"]');
let im = new Inputmask('+7 (999) 999-99-99');
im.mask(selector);

let validateForms = function(selector, rules, successModal, yaGoal) {

	new window.JustValidate(selector, {
		rules: rules,
		
		messages: {		    
			text: {
				minLength: 'Не менееe 5 символов',
				required: "Обязательное поле",
			},
			email: {
				required: 'Обязательное поле',
				email: "Некорректный email",
			},			
			myField: {
				required: "Обязательное поле",
				strength: "Неверный формат", 
			},			
	},		
		
		submitHandler: function(form) {

			let tk = '';
			grecaptcha.ready(function() {
				grecaptcha.execute('6LdQqOoaAAAAANeugDwBLuQzCkAHHwxtMS77Ujfr', {action: 'homepage'}).then(function(token) {
					tk = token;
					document.getElementById('token').value = token;
					
					let formData = new FormData(form);

        			fetch('../docs/mail.php', {
        				method: "POST",
        				body: formData,
        			})
        			.then(response => response.json())
        			.then(result => {
        				if (result['score'] >= 0.5 && !document.querySelector('.feedback-form__success')) {
									document.querySelector('.feedback__inner').insertAdjacentHTML('afterbegin', '<span class="feedback-form__success">Спасибо! Данные отправлены</span>');
									form.remove();	
        				} else if((result['score'] < 0.5) && !document.querySelector('.feedback-form__unsuccess')) {
        						document.querySelector('.feedback__inner').insertAdjacentHTML('afterbegin', '<span class="feedback-form__unsuccess">Вы не прошли проверку. Если вы человек, то обратитесь на почту arzamaskinkirill@gmail.com</span>');
        				} else{
									if(!document.querySelector('.feedback-form__unsuccess')){
										document.querySelector('.feedback__inner').insertAdjacentHTML('afterbegin', '<span class="feedback-form__unsuccess">Ошибка. Повторите позже</span>');
									}
								}
        			})
				})
			})
		}
	});
}

validateForms('.feedback-form', { email: {required: true, email: true}, myField: {required: true, strength: {custom: '[^_]$'},}, text: {required: true, minLength: 5}, }, '.thanks-popup', 'send goal');